import { inject, Injectable, Signal } from '@angular/core';
import { FirebaseLegacyService } from './firebase-legacy.service';
import { combineLatest, map, Observable, shareReplay, tap } from 'rxjs';
import { toSignal } from '@angular/core/rxjs-interop';
import { CustomField, FirebaseCollection } from '../../shared/models/models';
import { Member } from '../../features/members/members.models';
import { SfaEventType, SfaEvent } from '../../features/events/events.models';

@Injectable({
  providedIn: 'root',
})
/**
 * DataPrepperService is DEPRECATED! Use the store instead
 *
 * This service is responsible for preparing the data for the components. It should be the middleware between the
 * firebase-service and the components. As the firebase-service should only work with observables, this service should
 * prepare the data (mapping, filtering, IDK...) and then return signals instead of observables. This way, the components
 * always have fresh signals and don't have to worry about subscriptions and unsubscribing.
 */
export class DataPrepperService {
  private firebaseService = inject(FirebaseLegacyService);

  get members$(): Signal<Member[]> {
    return toSignal(this.members) as Signal<Member[]>;
  }

  get membersWithEvents$(): Signal<Member[]> {
    return toSignal(
      combineLatest([this.members, this.events]).pipe(
        map(([members, events]) => {
          return members.map((member) => {
            const memberEvents = events.filter(
              (event) => event.memberIds?.includes(member.id),
            );
            const eventCount = memberEvents.length;
            member.events = memberEvents;
            member.fullName = member.lastname + ' ' + member.firstname;
            return { ...member, memberEvents, eventCount };
          });
        }),
        shareReplay(1),
      ),
    ) as Signal<Member[]>;
  }

  get memberCustomFields$(): Signal<CustomField[]> {
    return toSignal(this.memberCustomFields) as Signal<CustomField[]>;
  }

  get events$(): Signal<SfaEvent[]> {
    return toSignal(this.events) as Signal<SfaEvent[]>;
  }

  get eventsWithMembers$(): Signal<SfaEvent[]> {
    return toSignal(this.eventsWithMembers) as Signal<SfaEvent[]>;
  }

  getEventDetailById$(id: string): Signal<SfaEvent> {
    return toSignal(
      this.eventsWithMembers.pipe(
        map((events) => events.filter((event) => event.id === id).at(0)),
      ),
    ) as Signal<SfaEvent>;
  }

  get eventCustomFields$(): Signal<CustomField[]> {
    return toSignal(this.eventCustomFields) as Signal<CustomField[]>;
  }

  get eventColumns$(): Signal<string[]> {
    return toSignal(
      this.eventCustomFields.pipe(
        map((fields) => {
          return [
            ...['title', 'date', 'memberCount', 'type', 'description'],
            ...fields.map((field) => 'custom_' + field.id),
          ];
        }),
        shareReplay(1),
      ),
    ) as Signal<string[]>;
  }

  get eventTypes$(): Signal<SfaEventType[]> {
    return toSignal(this.eventTypes) as Signal<SfaEventType[]>;
  }

  private get eventTypes(): Observable<SfaEventType[]> {
    return this.firebaseService
      .getCollection(FirebaseCollection.EVENT_TYPES)
      .pipe(shareReplay(1));
  }

  private get eventCustomFields(): Observable<CustomField[]> {
    return this.firebaseService
      .getCollection(FirebaseCollection.EVENT_CUSTOM_FIELDS)
      .pipe(shareReplay(1));
  }

  private get memberCustomFields(): Observable<CustomField[]> {
    return this.firebaseService
      .getCollection(FirebaseCollection.MEMBER_CUSTOM_FIELDS)
      .pipe(shareReplay(1));
  }

  private get members(): Observable<Member[]> {
    return this.firebaseService
      .getCollection(FirebaseCollection.MEMBERS)
      .pipe(shareReplay(1));
  }

  private get events(): Observable<SfaEvent[]> {
    return <Observable<SfaEvent[]>>(
      this.firebaseService
        .getCollection(FirebaseCollection.EVENTS)
        .pipe(shareReplay(1))
    );
  }

  private get eventsWithMembers(): Observable<SfaEvent[]> {
    return <Observable<SfaEvent[]>>combineLatest([
      this.members,
      this.events,
      this.eventTypes,
    ]).pipe(
      map(([members, events, eventTypes]) => {
        return events.map((rawEvent: SfaEvent): SfaEvent => {
          const event = rawEvent;
          event.type =
            eventTypes.find((type) => type.id === event.typeId)?.name ||
            'Standard';
          !event.memberCount && (event.memberCount = 0);
          event.members = members.filter(
            (member) => event.memberIds?.includes(member.id),
          );
          return event;
        });
      }),
      shareReplay(1),
    );
  }
}
