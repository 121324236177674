import { computed, inject, Injectable, Signal } from '@angular/core';
import { DataPrepperService } from './data-prepper.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { toSignal } from '@angular/core/rxjs-interop';
import { Store } from '@ngxs/store';
import { OrganizationState } from '../store/organization/organization.state';
import { Organization } from '../store/organization/organization.model';

@Injectable({
  providedIn: 'root',
})
export class GrantingService {
  private dataPrepperService = inject(DataPrepperService);
  private toastr = inject(ToastrService);

  private currentOrganization = toSignal(
    inject(Store).select(OrganizationState.activeOrganization),
  ) as Signal<Organization>;
  private grantingLevel = computed(
    () => this.currentOrganization().grantingLevel || 0,
  );
  private members = this.dataPrepperService.members$;
  private memberCustomFields = this.dataPrepperService.memberCustomFields$;
  private events = this.dataPrepperService.events$;
  private eventCustomFields = this.dataPrepperService.eventCustomFields$;
  private eventTypes = this.dataPrepperService.eventTypes$;

  private planLimits: any = environment.planLimitsByGrantingLevel;

  isAllowedToCreateMember(): boolean {
    return (
      this.planLimits[this.grantingLevel()].maxMembers > this.members().length
    );
  }

  isAllowedToCreateCustomFieldMember(): boolean {
    return (
      this.planLimits[this.grantingLevel()].maxCustomMemberFields >
      this.memberCustomFields().length
    );
  }

  /**
   * Check if the user is allowed to create an event for the given year.
   * @param year
   */
  isAllowedToCreateEventForYear(year: number): boolean {
    if (
      this.grantingLevel() === 0 &&
      this.events().length >= environment.maxEventsInFreePlan
    ) {
      return false;
    }
    return (
      this.planLimits[this.grantingLevel()].maxEventsPerYear >
      this.events().filter(
        (event) => new Date(event.date).getFullYear() === year,
      ).length
    );
  }

  isAllowedToCreateCustomFieldEvent(): boolean {
    return (
      this.planLimits[this.grantingLevel()].maxCustomEventFields >
      this.eventCustomFields().length
    );
  }

  isAllowedToCreateEventType(): boolean {
    return (
      this.planLimits[this.grantingLevel()].maxEventTypes >
      this.eventTypes().length
    );
  }

  /**
   * This is used to notify the user that the limits of his plan is reached. Also the side-effects can be done here like navigating to the plan page.
   */
  limitReached() {
    this.toastr.error('Du hast das Limit deines Tarifs erreicht.');
  }
}
